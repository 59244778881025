import {emailInputRegex} from "../../../../../utils/globalConstants";
import {submitEvent, submitMerge, submitMiniAction} from "@otto-ec/tracking-bct";

const newsletterSubscribeFooterEmailRegex = emailInputRegex
let newsletterSubscribeFooterValidForm = false;

const newsletterSubscribeFooterForm = document.getElementById(
  "newsletter-subscribe-footer-form"
);
const newsletterSubscribeFooterEmailInput = document.getElementById(
  "newsletter-subscribe-footer-email-input"
) as HTMLInputElement;

const newsletterSubscribeFooterError = document.getElementById(
  "newsletter-subscribe-footer-error"
);

const newsletterSubscribeFooterText = document.getElementById(
    "newsletter-subscribe-footer-text"
) as HTMLInputElement;

const newsletterSubscribeFooterHeadlineId: string = "newsletter-footer-headline"
const newsletterSubscribeFooterEmailInputId: string = "newsletter-subscribe-footer-email-input"

newsletterSubscribeFooterForm?.addEventListener(
  "submit",
  newsletterSubscribeFooterOnSubmitHandler
);
newsletterSubscribeFooterEmailInput?.addEventListener(
  "blur",
  validateNewsletterSubscribeFooterEmailInputHandler
);
newsletterSubscribeFooterEmailInput?.addEventListener(
    "click",
    showNewsletterSubscribeFooterText
);

function validateNewsletterSubscribeFooterEmailInputHandler() {
  const inputValue = newsletterSubscribeFooterEmailInput?.value;
  const emptyEmailInput = document.getElementById("empty-email-input-note");
  const invalidEmailInput = document.getElementById("invalid-email-input-note");
  const minEmailInput = document.getElementById("min-length-email-input-note");
  const invalidFormBanner = document.getElementById("invalid-form-banner");
  const newsletterSubscribeFooterEmailInputDiv = document.getElementById(
    "newsletter-subscribe-footer-email-input-div"
  );

  if (inputValue.match(newsletterSubscribeFooterEmailRegex)) {
    newsletterSubscribeFooterEmailInputDiv?.classList.remove(
      "pl_input--invalid"
    );
    emptyEmailInput!.style.display = "none";
    invalidEmailInput!.style.display = "none";
    invalidFormBanner!.style.display = "none";
    minEmailInput!.style.display = "none";
    newsletterSubscribeFooterValidForm = true;
  } else if (inputValue === "") {
    newsletterSubscribeFooterEmailInputDiv?.classList.add("pl_input--invalid");
    emptyEmailInput!.style.display = "unset";
    invalidEmailInput!.style.display = "none";
    invalidFormBanner!.style.display = "none";
    minEmailInput!.style.display = "none";
    newsletterSubscribeFooterValidForm = false;
  } else if (inputValue.length <= 5) {
    newsletterSubscribeFooterEmailInputDiv?.classList.add("pl_input--invalid");
    minEmailInput!.style.display = "unset";
    emptyEmailInput!.style.display = "none";
    invalidEmailInput!.style.display = "none";
    invalidFormBanner!.style.display = "none";
    newsletterSubscribeFooterValidForm = false;
  } else {
    newsletterSubscribeFooterEmailInputDiv?.classList.add("pl_input--invalid");
    invalidEmailInput!.style.display = "unset";
    emptyEmailInput!.style.display = "none";
    invalidFormBanner!.style.display = "none";
    minEmailInput!.style.display = "none";
    newsletterSubscribeFooterValidForm = false;
  }
}

function showNewsletterSubscribeFooterText() {
  newsletterSubscribeFooterText!.style.display = "unset";
}

function showErrorContent() {
  newsletterSubscribeFooterError!.style.display = "unset";
}

const sendNewsletterFooterSubscription = async (emailInput: string) => {
  const payload = {
    email: emailInput,
  };
  try {
    const response = await fetch("/newsletter-subscription/subscribe", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    if (!(response.status >= 500)) {
      const validFormBanner = document.getElementById("valid-form-banner");
      validFormBanner!.style.display = "flex";
      showConfirmationEmailSheet();
      trackFooterSubscribeNlSuccess();
    } else {
      trackFooterSubscribeNlFailure();
      showErrorContent();
    }
  } catch (error) {
    console.error("Something went wrong:", error.message, error.name);
    showErrorContent();
  }
};

function showConfirmationEmailSheet() {
  var sheet = new o_global.pali.sheet({
    title: "Die Bestätigungs E-Mail wurde verschickt",
    url: "/newsletter/anmelden/footer/popover.html",
    menuContent:
      '<button type="button" class="pl_button100--secondary" onclick="o_global.pali.sheet.getActiveSheet().close()"><span>Schließen</span></button>',
  });
  sheet.open();
}

function newsletterSubscribeFooterOnSubmitHandler(e: Event) {
  e.preventDefault();
  validateNewsletterSubscribeFooterEmailInputHandler();
  if (newsletterSubscribeFooterValidForm === true) {
    const payloadEmailInputVal = newsletterSubscribeFooterEmailInput?.value;
    if (!!payloadEmailInputVal) {
      sendNewsletterFooterSubscription(payloadEmailInputVal);
    }
  } else {
    const invalidFormBanner = document.getElementById("invalid-form-banner");
    invalidFormBanner!.style.display = "flex";
  }
}

/*                  */
function trackFooterSubscribeNlSuccess() {
  submitEvent({
    user_Permission: ['Newsletter'],
    user_PermissionActivity: ["true_ok_snippet"],
  });
}

function trackFooterSubscribeNlFailure() {
  submitEvent({
      user_Permission: ['Newsletter'],
      user_PermissionActivity: ["true_error_snippet"],
  });
}

(function trackFooterInitialScrollEvent() {
  submitMerge({}, [
    {
      id: "nl_snippet",
      name: "NL Snippet",
      status: "loaded",
      labels: {}
    },

    {
      id: "nl_snippet_1",
      parentId: "nl_snippet",
      name: "NL Snippet Headline",
      status: "hidden",
      labels: {}
    },

    {
      id: "nl_snippet_2",
      parentId: "nl_snippet",
      name: "NL Snippet Email",
      status: "hidden",
      labels: {}
    }
  ])}
)()

function checkNewsletterFooterVisibility(callback: (targetElementId: string) => void, targetElementId: string): void {
  const targetElement: HTMLElement | null = document.getElementById(targetElementId);

  if (targetElement) {
    function handleScroll(): void {
      const elementRect: DOMRect = targetElement.getBoundingClientRect();

      /*                         */
      const isElementVisible: boolean =
          elementRect.top <= window.innerHeight && elementRect.bottom >= 0;

      if (isElementVisible) {
        callback(targetElementId);

        window.removeEventListener('scroll', handleScroll);
      }
    }
    window.addEventListener('scroll', handleScroll);
  }
}

function trackNewsletterFooterVisibility(targetElementId: string): void {
  if (targetElementId === newsletterSubscribeFooterHeadlineId) {
    submitMiniAction([
      {
        featureId: 'nl_snippet',
        action: 'scroll',
        status: 'visible'
      },
      {
        featureId: 'nl_snippet_1',
        action: 'scroll',
        status: 'visible'
      }
    ]);
  } else {
    submitMiniAction([
      {
        featureId: 'nl_snippet_2',
        action: 'scroll',
        status: 'visible'
      }
    ]);
  }
}

checkNewsletterFooterVisibility(trackNewsletterFooterVisibility, newsletterSubscribeFooterHeadlineId)
checkNewsletterFooterVisibility(trackNewsletterFooterVisibility, newsletterSubscribeFooterEmailInputId)